export default ({
    state: {
        rules: {
            isRequired: v => !!v,
            isCorrectEmail: (v) => /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/.test(v),
            isCorrectPhone: (v) => /^((8|\+7)[\- ]?)?(\(?\d{3,4}\)?[\- ]?)?[\d\- ]{10}$/.test(v),
            onlyLatin: (v) => /^.[^А-Яа-я]*$/.test(v),
            digitsReq: (v) => /^(?=.*?[0-9])/.test(v),
            minLength: (v, l = 8) => (!!v & v.length >= l),
        }
    },
    getters: {
        Rules(state) {
            return state.rules;
        }
    },
})