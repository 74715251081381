export default ({
  state: {
    specialitiesList: [],
    specialityDetail: {},
  },
  getters: {
    specialitiesList: s => s.specialitiesList,
    specialityDetail: s => s.specialityDetail,
  },
  mutations: {
    updateSpecialitiesList(state, specialitiesList) {
      state.specialitiesList = specialitiesList.map((el) => ({label: el.title, value: el.id}))
    },
    updateSpecialityDetail(state, specialityDetail) {
      state.specialityDetail = specialityDetail
    },
  },
  actions: {
    async registration(ctx, userData) {
      const fd = new FormData();
      for (let data in userData) {
        fd.append(data, userData[data])
      }
        return await this.$axios({
          method: 'POST',
          url: '/register/',
          headers: {
            Authorization: null
          },
          data: {...userData},
        })
          .then(async (response) => {
            const result = response.data;
            result.status = result.sso_status
            delete result.sso_status
            return result

          })
          .catch((error) => {
            // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
            console.error(error)
            return false
          });
      

    },
    async checkEmail(ctx, { email, captcha, sendCode, subscribe }) {
      const fd = new FormData();
      fd.append('email', email);
      fd.append('captcha', `${captcha}`);
      fd.append('send', `${sendCode}`);
      if (subscribe) {
        fd.append('allow', subscribe);
      }
      return this.$axios({
        method: 'POST',
        url: `/confirm-email/send-pin/`,
        headers: {
          Authorization: null
        },
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          if (error.response.data && error.response.data && error.response.data.email && error.response.data.email === 'User already exists!') {
            return 'login'
          } else {
            return false
            // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }
        });
    },
    checkEmailCode(ctx, data) {
      const fd = new FormData();
      fd.append('pin', data.code);
      if (data.subscribe) {
        fd.append('allow', data.subscribe);
      }


      return this.$axios({
        method: 'POST',
        url: '/confirm-email/check-pin/',
        headers: {
          Authorization: null
        },
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }

          return true
        })
        .catch((error) => {
          console.error(error);
          return false
        });
    },
    checkPhone(ctx, obj) {
      const fd = new FormData();
      fd.append('phone', `7${obj.phone}`);
      fd.append('source', `${obj.source}`);
      fd.append('captcha', `${obj.captcha}`);

      return this.$axios({
        method: 'POST',
        url: '/confirm-phone/send-pin/',
        headers: {
          Authorization: null
        },
        data: fd,
      })
        .then(async (response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          // console.log(error)
          if (error && error.response && error.response.data && error.response.data.phone && error.response.data.phone === 'User already exists!') {
            return 'login'
          } else {
            ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
            return false
          }
        });
    },
    checkPhoneResend(ctx) {
      return this.$axios({
        method: 'POST',
        url: '/confirm-phone/resend/',
        headers: {
          Authorization: null
        },
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' }) 
          console.error(error);
          return false
        });
    },
    checkPhoneCode(ctx, data) {
      const fd = new FormData();
      fd.append('pin', data.code);
      if (data.subscribe) {
        fd.append('allow', data.subscribe);
      }

      return this.$axios({
        method: 'POST',
        url: '/confirm-phone/check-pin/',
        headers: {
          Authorization: null
        },
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          console.error(error);
          return false
        });
    },
    fetchSpecialitiesList(ctx) {
      return this.$axios({
        method: 'GET',
        url: '/specialities/',
        headers: {
          Authorization: null
        },
      })
        .then((response) => {
          
          if (response.data) {
            ctx.commit('updateSpecialitiesList', response.data)
          }
          return true
        })
        .catch((error) => {
          console.error(error);
          return false
        });
    },
    fetchSpecialityDetail(ctx, id) {
      return this.$axios({
        method: 'GET',
        url: `/specialities/${id}/`,
        headers: {
          Authorization: null
        },
      })
        .then((response) => {
          if (response.data) {
            ctx.commit('updateSpecialityDetail', response.data)
          }
          return true
        })
        .catch((error) => {
          console.error(error);
          return false
        });
    }
  },
})