import Vue from "vue";
import VueRouter from "vue-router";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);
  const routes = [ 
    {
      path: "/",
      name: "MainPage",
      redirect: () => ({name: "Login"}),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/restore-password",
      name: "RestorePassword",
      component: load("auth/RestorePassword"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/signup",
      name: "Register",
      component: load("auth/Register"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/signin",
      name: "Login",
      component: load("auth/Login"),
      meta: {
        layout: "auth",
      },
    },

    {
      path: "/error",
      name: "ErrorPage",
      component: load("auth/Error"),
      meta: {
        layout: "empty",
      },
    },
    
    {
      path: "*",
      name: "404",
      redirect: () => ({name: "Login"}),
      component: load("404"),
    },
  ];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  if(to && to.name !== "ErrorPage") {
    if(to.query && !to.query.source ) {
      next({name: "ErrorPage"})
    }
    next()
  } else {
    next()
  }
 
})

export default router;
