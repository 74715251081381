export default {
  state: {
    alerts: {},
  },
  getters: {
    getAlerts: s => s.alerts, 
  },
  mutations: {
    setAlerts(s, alerts) {
      s.alerts = alerts
    },
    
  },
  actions: {
    async fetchAlerts(ctx, { alerts, type }) {
      let obj = {
        type: type,
        alerts: alerts,
      }
      ctx.commit('setAlerts', obj)
    },
    async clearAlerts(ctx) {
      ctx.commit('setAlerts', {})
    },
  },
}
