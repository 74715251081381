export default ({
  state: {
  
  },
  getters: {
   
  },
  mutations: {
   
  },
  actions: {
    async auth(ctx, userData) {
      this.$axios.defaults.headers.common.Authorization = null
      const fd = new FormData();
      for (let data in userData) {
        fd.append(data, userData[data])
      }

      return await this.$axios({
        method: 'POST',
        url: '/login/',
        data: fd,
      })
        .then(async (response) => {
          const result = response.data;
          result.status = result.sso_status
          delete result.sso_status
          return result
        })
        .catch((error) => {
          if(error && error.response && error.response.data && error.response.data && error.response.data.login) {

            ctx.dispatch('fetchAlerts', {alerts: 'Неверный email или пароль', type: 'error'})
          } else {
    
          }
         
          return false
        });
    },
    authPhone(ctx, obj) {
      const fd = new FormData();
      fd.append('phone', `7${obj.phone}`); 
      fd.append('captcha', `${obj.captcha}`); 

      return this.$axios({
        method: 'POST',
        url: '/login/phone/send/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          if(error && error.response && error.response.data && error.response.data.phone) {
   
            ctx.dispatch('fetchAlerts', {alerts: 'Пользователя с таким номером телефона не существует', type: 'error'})
          } else if(error && error.response && error.response.data && error.response.data.requestError) {
      
            ctx.dispatch('fetchAlerts', {alerts: 'Слишком много запросов на отправку СМС-кода, попробуйте позже', type: 'error'})
          } else if(error && error.response && error.response.data && error.response.data.captcha) {

            ctx.dispatch('fetchAlerts', {alerts: 'Ошибка при проверке CAPTCHA, попробуйте позже', type: 'error'})
          } else {
            // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          }
          console.error(error);
          return false
        });
    },
    authPhoneResend(ctx) {
      return this.$axios({
        method: 'POST',
        url: '/login/phone/resend/',
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);
          return false
        });
    },
    authPhoneCode(ctx, code) {
      const fd = new FormData();
      fd.append('pin', code);

      return this.$axios({
        method: 'POST',
        url: '/login/phone/check/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {

            return false
          }

          const result = response.data;
          result.status = result.sso_status
          delete result.sso_status
          return result
        })
        .catch((error) => {
          // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);

          return false
        });
    },
  },
})