export default {
  state: {

  },
  getters: {
  
  },
  mutations: {
   
  },
  actions: {
    async restorePasswordStart(ctx, obj) {
      const fd = new FormData();
      fd.append('email', obj.email);
      fd.append('captcha', obj.captcha);
      let query;
      if (obj && obj.query) {
        query = "?" + new URLSearchParams(obj.query).toString()
      }

      return this.$axios({
        method: 'POST',
        url: `/restore-password/start/${query}`,
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.user && error.response.data.user === 'User not found') {

            ctx.dispatch('fetchAlerts', { alerts: 'Пользователя с такой почтой не существует', type: 'error' })
          } else if (error && error.response && error.response.data && error.response.data.captcha) {
 
            ctx.dispatch('fetchAlerts', { alerts: 'Ошибка при проверке CAPTCHA, попробуйте позже', type: 'error' })
          } else {
            // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }

          console.error(error);
          return false
        });
    },
    restorePassword(ctx, passData) {

      const fd = new FormData();
      for (let data in passData) {
        fd.append(data, passData[data])
      }

      return this.$axios({
        method: 'POST',
        url: '/restore-password/change/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          let response = JSON.parse(error.request.response)
          if (response && response.non_field_errors) {
           return response.non_field_errors
          } else {
            // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }
          console.error(error);
          return false
        });
    },
  },
}
