export default {
  state: {
    user: {},
  },
  getters: {
    user: (s) => s.user,
  },
  mutations: {
    updateUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    getSession(ctx) {
      return this.$axios({
        method: "GET",
        url: '/auth/session',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateUser", result);
          
          return true;
        })
        .catch((error) => {
          console.error(error);
          if (error && error.request && error.request.status !== 401) {
            if (error.request.status === 403 && error.response.data.error.includes("ban")) {
              ctx.commit("updateUser", 'ban');
              // ctx.dispatch("fetchAlerts", {
              //   alerts: "Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут",
              //   type: "error",
              // });
            } else {
              ctx.dispatch("fetchAlerts", {
                alerts: "Произошла ошибка",
                type: "error",
              });
            }

          }
          return false;
        });
    },
    getUserInfo(ctx, id) {
      return this.$axios({
        method: "GET",
        url: `/user/${id}/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("updateUser", result);
        })
        .catch((error) => {
          if (error && error.request && error.request.status !== 401) {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
        });
    },
    async changeUserInfo(ctx, userData) {
      const fd = new FormData();
      let dataObj = {}
      for (let data in userData.fields) {
        dataObj[data] = userData.fields[data]
        if (userData.fields[data].size) {
          fd.append(data, userData.fields[data])
          dataObj = fd
        }
      }
      return this.$axios({
        method: "PUT",
        url: `/user/${userData.id}/`,
        data: dataObj,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(async (response) => {
          await ctx.dispatch("getUserInfo", userData.id);
          if (userData.alert) {
            ctx.dispatch("fetchAlerts", {
              alerts: userData.alert,
              type: "notify",
            });
          }
          return true;
        })
        .catch((error) => {
          ctx.dispatch("fetchAlerts", {
            alerts: "Произошла ошибка",
            type: "error",
          });
          return false;
        });
    },
    async changeUserPass(ctx, userData) {
      const fd = new FormData();
      fd.append("user_id", userData.id);
      fd.append("new_password", userData.password);
      return this.$axios({
        method: "POST",
        url: `/user/${userData.id}/password/`,
        data: fd,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(async (response) => {
          ctx.dispatch("fetchAlerts", {
            alerts: "Пароль успешно изменен",
            type: "notify",
          });
          return true;
        })
        .catch((error) => {
          let response = JSON.parse(error.request.response);
          if (response && response.non_field_errors) {
            ctx.commit("updateInputError", response.non_field_errors[0]);
          } else {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
          return false;
        });
    },

    deleteUserAvatar(ctx, id) {
      return this.$axios({
        method: "DELETE",
        url: `/user/${id}/photo`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          ctx.dispatch("getUserInfo", id);
          return true;
        })
        .catch((error) => {
          if (error.request.status !== 401) {
            ctx.dispatch("fetchAlerts", {
              alerts: "Произошла ошибка",
              type: "error",
            });
          }
          return false;
        });
    },
  },
};
