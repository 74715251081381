import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex, { mapActions, mapGetters } from 'vuex'
import axios from 'axios';
import VueMeta from 'vue-meta'


import vSelect from "vue-select";
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually


axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}/`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = null;


import './assets/scss/styles.scss' 
Vue.component("v-select", vSelect);
Vue.use(VueCroppie);
Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vuex.Store.prototype.$axios = axios

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})


export const bus = new Vue();

new Vue({
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  computed: {
    ...mapGetters(['user']),
    host() {
      return process.env.VUE_APP_BACKEND_HOST;
    },

    isMobile: function () {
      return this.windowWidth < 768;
    },
    isTablet: function () {
      return this.windowWidth < 1220 && this.windowWidth > 767;
    },

  },
  methods: {
    ...mapActions(['fetchAlerts'])
  },
  async mounted() { 
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    function request(request) {
      const axiosSource = axios.CancelToken.source();
      request.cancelToken = axiosSource.token;
      store.dispatch("addRequest", {
        ...request,
        cancel: axiosSource.cancel,
      });
      return request;
    }

    function requestError() {
      store.commit("requestFailed");
    }
    await axios.interceptors.request.use(request, requestError);
    await axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // const originalRequest = error.config;
        if (
          error &&
          error.response &&
          error.response.status
        ) {
          if(error.response.status === 429) {
            this.fetchAlerts({ alerts: 'Слишком много запросов попробуйте позже', type: 'error' })
            return Promise.reject(false);
          // } else {
          //   console.log(error.response.status)
          //   this.fetchAlerts({ alerts: 'Произошла ошибка', type: 'error' })
          //   // return Promise.reject(error);
          }
          
        }
        return Promise.reject(error);
      })
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
