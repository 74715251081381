<template>
  <div id="app">
    <Alert />
    <vue-extend-layouts />
    <RegPopup v-if="RegPopupState" @close="RegPopupToggle" />
    
  </div>
</template>

<script>
import VueExtendLayouts from "vue-extend-layout";
import Alert from "./components/pageComponents/Alert.vue";
import RegPopup from "./components/pageComponents/RegPopup.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
export default {
  name: "App",
  components: {
    VueExtendLayouts,
    RegPopup,
    Alert,
  },
  data: () => ({
    RegPopupState: false,
  }),
  methods: {
    RegPopupToggle(state) {
      this.RegPopupState = state;
    },
  },
  mounted() {
    bus.$on("openPolicy", this.RegPopupToggle);
  },
  destroyed() {
    bus.$off("openPolicy", this.RegPopupToggle);
  },
};
</script>

<style lang="scss">
</style>
